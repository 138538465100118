/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";
import View from "layouts/sections/components/View";

// BlogPosts page components
import BlogPostOne from "./components/BlogPostOne";

// import BlogPostTwo from "layouts/sections/page-sections/blog-posts/components/BlogPostTwo";
import BlogPostThree from "./components/BlogPostThree";
// import BlogPostFour from "layouts/sections/page-sections/blog-posts/components/BlogPostFour";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
// import { Container } from "@mui/system";
// import { Grid } from "@mui/material";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import routes from "routes";
import footerRoutes from "footer.routes";
// // BlogPosts page components code
// import blogPostOneCode from "layouts/sections/page-sections/blog-posts/components/BlogPostOne/code";
// import blogPostTwoCode from "layouts/sections/page-sections/blog-posts/components/BlogPostTwo/code";
// import blogPostThreeCode from "layouts/sections/page-sections/blog-posts/components/BlogPostThree/code";
// import blogPostFourCode from "layouts/sections/page-sections/blog-posts/components/BlogPostFour/code";

function News() {
  return (
    <>
      <DefaultNavbar routes={routes} />{" "}
      <MKBox component="section" py={7}>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <BlogPostOne />
          <BlogPostThree />
        </Card>
      </MKBox>
      <MKBox px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default News;
