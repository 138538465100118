// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "ACG",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "",

      // link: "https://www.facebook.com/CreativeTim/",
    },
    {
      icon: <TwitterIcon />,
      link: "",

      // link: "https://twitter.com/creativetim",
    },
    {
      icon: <GitHubIcon />,
      link: "",

      // link: "https://github.com/creativetimofficial",
    },
    {
      icon: <YouTubeIcon />,
      link: "",

      // link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
    },
  ],
  menus: [
    {
      name: "company",
      items: [
        {
          name: "about us",
          href: "",
          //  href: "https://www.creative-tim.com/presentation"
        },
        { name: "blog", href: "" },
      ],
    },
    {
      name: "resources",
      items: [
        {
          name: "illustrations",
          href: "",
          //  href: "https://iradesign.io/"
        },
        {
          name: "bits & snippets",
          // href: "https://www.creative-tim.com/bits"
          href: "",
        },
        {
          name: "affiliate program",
          href: "",
          // href: "https://www.creative-tim.com/affiliates/new"
        },
      ],
    },
    {
      name: "help & support",
      items: [
        { name: "contact us", href: "" },
        // { name: "knowledge center", href: "https://www.creative-tim.com/knowledge-center" },
        { name: "custom development", href: "" },
        { name: "sponsorships", href: "" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "terms & conditions", href: "" },
        { name: "privacy policy", href: "" },
        { name: "licenses", href: "" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} ACG{" "}
      {/* <MKTypography
        component="a"
        href="https://www.creative-tim.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Creative Tim
      </MKTypography> */}
      .
    </MKTypography>
  ),
};
