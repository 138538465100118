/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import routes from "routes";
import footerRoutes from "footer.routes";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React examples
import ColoredBackgroundCard from "examples/Cards/BackgroundCards/ColoredBackgroundCard";
import SimpleBackgroundCard from "examples/Cards/BackgroundCards/SimpleBackgroundCard";
import ResearchTwo from "./components/ReserachCards";

function Research() {
  return (
    <>
      <DefaultNavbar routes={routes} />

      <MKBox component="section" py={6}>
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            flexDirection="column"
            textAlign="center"
            mx="auto"
            mb={3}
          >
            <MKTypography
              variant="body2"
              color="info"
              fontWeight="bold"
              textTransform="uppercase"
              textGradient
            >
              Our Work
            </MKTypography>
            <MKTypography variant="h3" mt={5}>
              Some of our awesome Researchs
            </MKTypography>
          </Grid>
          <Grid container spacing={3} mt={4}>
            <Grid item xs={12} md={6} lg={4}>
              <ColoredBackgroundCard
                color="dark"
                image="https://plus.unsplash.com/premium_photo-1661951318614-85187440d168?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8QWZyaWNhJTIwY2xpbWF0ZXxlbnwwfHwwfHx8MA%3D%3D"
                label="Productivity"
                title="Search and Discover!"
                description="Don't be scared of the truth because we need to restart the human foundation in
                    truth And I love you like Kanye loves Kanye I love Rick Owens’ bed design."
                action={{
                  type: "internal",
                  route: "/Reserach/ResearchDetail",
                  label: "Read More",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ColoredBackgroundCard
                color="dark"
                image="https://plus.unsplash.com/premium_photo-1661951318614-85187440d168?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8QWZyaWNhJTIwY2xpbWF0ZXxlbnwwfHwwfHx8MA%3D%3D"
                label="Design"
                title="Find music and play it!"
                description="As we live, our hearts turn colder. Cause pain is what we go through as we
                    become older. We get insulted by others, lose trust for those others."
                action={{
                  type: "internal",
                  route: "/Reserach/ResearchDetail",
                  label: "Read More",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ColoredBackgroundCard
                color="dark"
                image="https://plus.unsplash.com/premium_photo-1661951318614-85187440d168?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8QWZyaWNhJTIwY2xpbWF0ZXxlbnwwfHwwfHx8MA%3D%3D"
                label="Support"
                title="Check bugs and fix!"
                description="If you have the opportunity to play this game of life you need to appreciate
                    every moment. A lot of people don’t appreciate the moment until it’s passed."
                action={{
                  type: "internal",
                  route: "/Reserach/ResearchDetail",
                  label: "Read More",
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={4}>
            <Grid item xs={12} md={6} lg={4}>
              <ColoredBackgroundCard
                color="dark"
                image="https://plus.unsplash.com/premium_photo-1661951318614-85187440d168?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8QWZyaWNhJTIwY2xpbWF0ZXxlbnwwfHwwfHx8MA%3D%3D"
                label="Productivity"
                title="Search and Discover!"
                description="Don't be scared of the truth because we need to restart the human foundation in
                    truth And I love you like Kanye loves Kanye I love Rick Owens’ bed design."
                action={{
                  type: "internal",
                  route: "/Reserach/ResearchDetail",
                  label: "Read More",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ColoredBackgroundCard
                color="dark"
                image="https://plus.unsplash.com/premium_photo-1661951318614-85187440d168?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8QWZyaWNhJTIwY2xpbWF0ZXxlbnwwfHwwfHx8MA%3D%3D"
                label="Design"
                title="Find music and play it!"
                description="As we live, our hearts turn colder. Cause pain is what we go through as we
                    become older. We get insulted by others, lose trust for those others."
                action={{
                  type: "internal",
                  route: "/Reserach/ResearchDetail",
                  label: "Read More",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ColoredBackgroundCard
                color="dark"
                image="https://plus.unsplash.com/premium_photo-1661951318614-85187440d168?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8QWZyaWNhJTIwY2xpbWF0ZXxlbnwwfHwwfHx8MA%3D%3D"
                label="Support"
                title="Check bugs and fix!"
                description="If you have the opportunity to play this game of life you need to appreciate
                    every moment. A lot of people don’t appreciate the moment until it’s passed."
                action={{
                  type: "internal",
                  route: "/Reserach/ResearchDetail",
                  label: "Read More",
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <ResearchTwo />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Research;
