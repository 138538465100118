/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";

function Places() {
  const actionProps = {
    type: "internal",
    route: "/Reserach/ResearchDetail",
    color: "dark",
    label: "find more",
  };

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          xs={12}
          lg={6}
          sx={{ textAlign: "center", mx: "auto" }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="ACG"
            container
            circular
            sx={{ mb: 1 }}
          />
          <MKTypography variant="h2" mb={1}>
            Explore our researchs{" "}
          </MKTypography>
          <MKTypography variant="body2" color="text">
            If you can&apos;t decide, the answer is no. If two equally difficult
            paths, choose the one more painful in the short term (pain avoidance
            is creating an illusion of equality).
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image="https://media.istockphoto.com/id/1145883808/photo/dried-lake-and-river-on-summer-and-climate-change-concept.jpg?s=612x612&w=0&k=20&c=ou_gjr2XK2_8WIJGmsp0_xRgXay7dgHUDs2pHgZCXEI="
                title="Assessing Climate Change Impacts on Agriculture in Sub-Saharan Africa: A Multi-Scale Analysis"
                description="Website visitors today demand a frictionless user expericence. Applies to mobile applications too."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image="https://plus.unsplash.com/premium_photo-1661951318614-85187440d168?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8QWZyaWNhJTIwY2xpbWF0ZXxlbnwwfHwwfHx8MA%3D%3D"
                title="Exploring the Vulnerability of African Coastal Communities to Climate Change: A Case Study Approach"
                description="If you're more passionate about founding a business than the business itself technology."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image="https://plus.unsplash.com/premium_photo-1661958479963-f2e137acb9ed?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8QWZyaWNhJTIwY2xpbWF0ZXxlbnwwfHwwfHx8MA%3D%3D"
                title="Examining the Role of Climate Variability in Water Resource Management Across African"
                description="Website visitors today demand a frictionless user expericence — especially when using search."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image="https://images.unsplash.com/photo-1628984929118-af07f426c3fa?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mzh8fEFmcmljYSUyMGNsaW1hdGV8ZW58MHx8MHx8fDA%3D"
                title="Mitigating Climate-Induced Health Risks in Urban Centers of Africa: Strategies and Interventions"
                description="If you're more passionate about founding a business than the business itself, you can fall into."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image="https://images.unsplash.com/photo-1606117331555-09850ea3c083?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fEFmcmljYSUyMGNsaW1hdGV8ZW58MHx8MHx8fDA%3D"
                title="Understanding the Dynamics of Drought Patterns in Africa: Trends, Drivers, and Implications"
                description="Smart money is just dumb money that's been through a crash. Business than the business itself."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image="https://media.istockphoto.com/id/1214284146/photo/drought-and-water-scarcity.jpg?s=612x612&w=0&k=20&c=b9dbt8NTr9W8otrqnPynmpcBZAZaQ1XAYImcKbeY-yI="
                title="Analyzing the Socioeconomic Impacts of Climate Change on Rural Livelihoods in East Africa: Lessons Learned"
                description="Technology is not only the thing that moves the human race forward, but it's the only thing that has."
                action={actionProps}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
